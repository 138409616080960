.menuPanel {
  padding-left: 1rem;
  background-color: #f8f8f8;
}
.menu {
  border-left: #00a6cc;
  background-color: #f8f8f8;
  position: -webkit-sticky;
  position: sticky;
  overflow: scroll;
  top: 0;
  max-height: 100vh;

  padding: 1.5rem 0.5rem 2rem 2rem;
}
.menu::-webkit-scrollbar {
  width: 1rem;
  scroll-padding-top: 20px;
}
.menu::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  border-radius: 100px;
  border: 0.25rem solid transparent;
  background-clip: content-box;
}

.menu > li {
  position: relative;
  width: 95%;
  margin: 0.3rem 0rem 0.3rem 0.3rem;
  cursor: pointer;
  color: var(--pttLinkColor);
  font-size: 1rem;
}

.menu > li:after {
  content: "";
  position: absolute;
  left: -25px;
  top: 0px;
  width: 20px;
  height: 20px;
}
.menu > li i {
  position: absolute;
  top: 0.4rem;
  left: -1.4rem;
}
.menu > li > li i {
  position: absolute;
  top: 0.7rem;
  left: -1.8rem;
}
.menu > li > a:hover {
  font-weight: var(--fontWeight);
}

.menu > li > a > b {
  position: absolute;
  left: 95%;
}
.menu > li > a {
  display: flex;
  align-items: center;
  padding: 0rem 0rem 0.5rem 0rem;
  width: 100%;
  color: var(--pttLinkColor);
  font-size: 1rem;
}
.menu > li > a:hover {
  font-weight: var(--fontWeight);
}
.menu > li:first-child {
  margin-top: 0.8rem;
}

.menu > li > a:active {
  color: var(--pttYellowColor) !important;
}
.menu > li ul {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  padding: 0rem 1rem 0.5rem 1rem;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.menu > li ul li i {
  left: -1.2rem;
  top: 0.3rem;
}
.menu > li ul li {
  margin-left: 1rem;
}

.menuOpenOrClose {
  border-left: 0.1rem solid var(--pttYellowColor);
  border-radius: 0.1rem;
  visibility: visible !important;
  opacity: 1 !important;
  max-height: 1000px !important;
}
#left-menu {
  border-left: 0px !important;
}
.menuOpenOrClose > div {
  padding: 1rem 2rem !important;
}
.menuAcourdionOpenOrClose {
  visibility: visible !important;
  opacity: 1 !important;
  width: 100%;
  max-height: 1000px !important;
  background-color: #ffffff;
  padding: 1rem 2rem !important;
  display: inline-table;
}
.menuAcourdionOpenOrClose > div {
  padding: 1rem 2rem !important;
}

ul > li .iconChange::before {
  content: "\f068";

  color: var(--pttLinkColor);
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .menuPanel {
    order: 1 !important;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .menuPanel {
    order: 1 !important;
  }
}
