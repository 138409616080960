.menuPanel {
  padding-left: 1rem;
  background-color: #f8f8f8;
}

.menu {
  border-left: #00a6cc;
  background-color: #f8f8f8;
  position: -webkit-sticky;
  position: sticky;
  overflow: scroll;
  top: 0;
  max-height: 100vh;

  padding: 1.5rem 0.5rem 2rem 3rem;
}
.menu::-webkit-scrollbar {
  width: 1rem;
  scroll-padding-top: 20px;
}
.menu::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  border-radius: 100px;
  border: 0.25rem solid transparent;
  background-clip: content-box;
}

.menu > li {
  position: relative;
  width: 95%;
  margin: 0.3rem 0rem 0.3rem 0.3rem;
  cursor: pointer;
  color: var(--pttLinkColor);
  font-size: 1rem;
}
.menu > li:after {
  content: "";
  position: absolute;
  left: -25px;
  top: 0px;
  width: 20px;
  height: 20px;
}
.menu > li i {
  position: absolute;
  top: 0.25rem;
  left: -1.4rem;
}
.menu > li > li i {
  position: absolute;
  top: 0.7rem;
  left: -1.8rem;
}
.menu > li > a:hover {
  font-weight: var(--fontWeight);
}

.menu > li > a {
  display: block;

  padding: 0rem 0rem 0.5rem 0rem;
  width: 100%;
  color: var(--pttLinkColor);
  font-size: 1rem;
}
.menu > li > a:hover {
  font-weight: var(--fontWeight);
}
.menu > li:first-child {
  margin-top: 0.8rem;
}

.menu > li > a:active {
  color: var(--pttYellowColor) !important;
}
.menu > li ul {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  padding: 0rem 1rem 0.5rem 1rem;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu > li ul li i {
  left: -1.2rem;
}

.contentPanel {
  padding: 2rem 2rem 2rem 2rem;
  overflow: hidden;
  word-wrap: break-word;
}
.contentPanel > h1 {
  margin-bottom: 1rem;
  color: var(--pttLinkColor);
  font-size: 1.4rem !important;
}
.contentPanel h2 {
  display: inline-block;
  position: relative;
  margin-bottom: 0.5rem;
  color: var(--pttLinkColor);
  font-size: 1.1rem !important;
  line-height: 2rem;
}
.contentPanel h2::before {
  position: absolute;
  background: rgb(255, 199, 44);
  background: linear-gradient(
    90deg,
    rgba(255, 199, 44, 1) 0%,
    transparent 100%
  );
  content: "";
  width: 50%;
  height: 2px;
}
.contentPanel h2::after {
  position: absolute;
  top: 100%;
  right: 0;
  background: rgb(255, 199, 44);
  background: linear-gradient(270deg, rgb(0 158 192) 0%, transparent 100%);
  content: "";
  width: 50%;
  height: 2px;
}
.contentPanel p {
  position: relative;
  color: var(--pttLinkColor);
  text-align: justify;
}
.contentPanel table {
  width: auto !important;
}

.menuOpenOrClose {
  visibility: visible !important;
  opacity: 1 !important;
  max-height: 1000px !important;
}

ul > li .iconChange::before {
  content: "\f068";

  color: var(--pttLinkColor);
}
ul > li .active {
  visibility: visible;
}
.menuOpenOrClose > div {
  padding: 1rem 2rem !important;
}

.menuAcourdionOpenOrClose {
  visibility: visible !important;
  opacity: 1 !important;
  width: 100%;
  max-height: 1000px !important;
  background-color: #ffffff;
  padding: 1rem 2rem !important;
  display: inline-table;
}

.menuAcourdionOpenOrClose > div {
  padding: 1rem 2rem !important;
}
.collapse {
  display: hidden;
  opacity: 0;
  max-height: 0px;
  padding: 0 !important;
}
.pttBilgiBankasiContent {
  width: auto !important;
}
.slugAccordionTitle {
  width: auto !important;
}

.slugAccordionTitleText {
  width: auto !important;
  padding-right: 5px;
  font-weight: var(--fontWeight);
  transition: color 0.2s ease-in-out;
}

.slugAccordion {
  color: var(--pttLinkColor) !important;
  font-weight: var(--fontWeight) !important;

  transition: color 0.2s ease-in-out;
  border-radius: 0.3rem !important;
  background: #eee !important;
  border: 1px solid rgb(203 198 184) !important;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.slugSlider > section > ul > li > div > span > img {
  object-position: 70% !important;
}
.slugAccordion::after {
  font: var(--fa-font-solid);
  content: " \f0ab";
  float: right;
  position: absolute;
  margin-right: 0.5rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slugsAccordionIcon {
  float: none !important;
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .menuPanel {
    order: 1 !important;
  }

  .contentPanel table {
    width: 100% !important;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .menuPanel {
    order: 1 !important;
  }

  .contentPanel {
    overflow: scroll auto;
  }

  .contentPanel table {
    width: 100% !important;
  }

  .contentPanel img {
    width: 100% !important;
    object-fit: contain;
  }
}
